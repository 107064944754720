@font-face {
    font-family: 'Product Sans Regular';
    src: url('fonts/product-sans-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Product Sans Bold';
    src: url('fonts/product-sans-bold.woff2') format('woff2');
}

h1 {
    font-family: 'Product Sans Regular';
}

h2 {
    font-family: 'Product Sans Regular';
}

body {
    margin: 0;
    font-family: 'Product Sans Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* hide all scroll bars */
::-webkit-scrollbar {
    display: none;
}
